<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// import Index from './view/index.vue'

export default {
  name: 'App',
  created () {
    const IS_MOBILE = this._isMobile()
    if (IS_MOBILE) {
      console.log('mobile')
      this.$router.push('/mobile_index')
    } else {
      console.log('pc')
      this.$router.push('/index')
    }
  },
  methods: {
    _isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  }
}
</script>

<style lang="scss">
  @import './style/main.scss';
</style>
