import Vue from 'vue'
import VueRouter from 'vue-router'

const INDEX = () => import('@/view/index.vue')
const MOBILE_INDEX = () => import('@/view/mobile_index.vue')
const M_INDEX = () => import('@/view/m_index.vue')

Vue.use(VueRouter)

const routes = [
  { path: '', redirect: '/index' },
  {
    path: '/index',
    component: INDEX
  },
  {
    path: '/mobile_index',
    component: MOBILE_INDEX
  },
  {
    path: '/m_index',
    component: M_INDEX
  }
]

const router = new VueRouter({
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
