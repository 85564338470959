import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router/router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style
import 'swiper/swiper-bundle.css'

import Swiper1, { Autoplay } from 'swiper'
Swiper1.use([Autoplay])

Vue.use(VueAwesomeSwiper /* { default options with global component } */)
Vue.prototype.$api = axios
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
